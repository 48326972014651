import revive_payload_client_9VjHaZTKHf from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_encoding@0.1.13_idb-keyval@6.2.1_i_55b1adf2b033b1c04499a2433c257509/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Y8EnGgnPEW from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_encoding@0.1.13_idb-keyval@6.2.1_i_55b1adf2b033b1c04499a2433c257509/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_C9k9RU9IGS from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_encoding@0.1.13_idb-keyval@6.2.1_i_55b1adf2b033b1c04499a2433c257509/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_AfcESaxnje from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_encoding@0.1.13_idb-keyval@6.2.1_i_55b1adf2b033b1c04499a2433c257509/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_h492rqy3D8 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_encoding@0.1.13_idb-keyval@6.2.1_i_55b1adf2b033b1c04499a2433c257509/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_VkMS6XD8RD from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_encoding@0.1.13_idb-keyval@6.2.1_i_55b1adf2b033b1c04499a2433c257509/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ViDd4bqyF3 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_encoding@0.1.13_idb-keyval@6.2.1_i_55b1adf2b033b1c04499a2433c257509/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_qK95MBCrgd from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.4_vue@3.5.8_webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_EQ33ufZykj from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_encoding@0.1.13_idb-keyval@6.2.1_i_55b1adf2b033b1c04499a2433c257509/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iXkO8iyUBU from "/app/node_modules/.pnpm/nuxt-icon@1.0.0-beta.7_magicast@0.3.5_rollup@4.22.4_vite@5.4.8_@types+node@22.7.0_terse_2c8b877cc76d4f63243018aed45c0caa/node_modules/nuxt-icon/dist/runtime/plugin.js";
import switch_locale_path_ssr_WHi1V73lTn from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.4_vue@3.5.8_webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_EdCPuuepee from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.4_vue@3.5.8_webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _4_upKeep_HgmgosZx5b from "/app/plugins/4.upKeep.ts";
import _2_firebase_R5rFoiZKOj from "/app/plugins/2.firebase.ts";
import _3_sentry_client_ts_IsRn89mD46 from "/app/plugins/3.sentry.client.ts.ts";
import _1_airweb_lneS4MmBbC from "/app/plugins/1.airweb.ts";
import _4_clarity_client_8iHh3wh2u3 from "/app/plugins/4.clarity.client.ts";
export default [
  revive_payload_client_9VjHaZTKHf,
  unhead_Y8EnGgnPEW,
  router_C9k9RU9IGS,
  payload_client_AfcESaxnje,
  navigation_repaint_client_h492rqy3D8,
  check_outdated_build_client_VkMS6XD8RD,
  chunk_reload_client_ViDd4bqyF3,
  plugin_vue3_qK95MBCrgd,
  components_plugin_KR1HBZs4kY,
  prefetch_client_EQ33ufZykj,
  plugin_iXkO8iyUBU,
  switch_locale_path_ssr_WHi1V73lTn,
  i18n_EdCPuuepee,
  _4_upKeep_HgmgosZx5b,
  _2_firebase_R5rFoiZKOj,
  _3_sentry_client_ts_IsRn89mD46,
  _1_airweb_lneS4MmBbC,
  _4_clarity_client_8iHh3wh2u3
]