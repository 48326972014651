import validate from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_encoding@0.1.13_idb-keyval@6.2.1_i_55b1adf2b033b1c04499a2433c257509/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_encoding@0.1.13_idb-keyval@6.2.1_i_55b1adf2b033b1c04499a2433c257509/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/middleware/auth.ts"),
  "cart-account": () => import("/app/middleware/cartAccount.ts"),
  "cart-terms-accepted": () => import("/app/middleware/cartTermsAccepted.ts")
}